<template>
    <v-container>
        <v-layout row justify-center>
            <v-dialog
                    v-model="dialog"
                    max-width="350"
            >
                <v-card>
                    <v-card-title class="headline" v-text="`${delsta.DDATE}, ${delsta.TTIME}, ${delsta.STATYPE}`"/>
                    <v-spacer></v-spacer>
                    <v-card-subtitle v-text="`${delsta.MED}`"/>
                    <v-card-text v-text="`${delsta.INFO}`"/>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                                color="primary"
                                @click="dialog = false"
                        >
                            Обратно
                        </v-btn>

                        <v-btn
                                color="error"
                                @click="dialogDelStaDo"
                        >
                            Удалить
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>


        <v-row>

            <v-col
                    cols="12"
                    sm="6"
            >
                <v-card
                        class="pa-2"
                        elevation="0"
                >
                    <!--v-card-title></1--v-card-title-->
                    <h2>{{header}}</h2>
                    <v-alert type="error"
                             close-text="Закрыть сообщение"
                             dismissible
                             v-model="alertError.show"
                             @click="navBack()"
                    >
                        {{alertError.text}}
                    </v-alert>
                    <v-alert type="warning"
                             close-text="Закрыть сообщение"
                             dismissible
                             v-model="alertWarning.show"
                             @click="navBack()"
                    >
                        {{alertWarning.text}}
                    </v-alert>
                    <v-btn v-if="navBackShow" block
                           color="primary"
                           @click.prevent="navBack()"
                           elevation="10"
                    >
                        Обратно
                    </v-btn>
                    <v-card
                            class="mt-6 pa-2"
                            elevation="10"
                            outlined
                            v-model="arrsta"
                            v-for="(e,i) in arrsta"
                            :key="i"
                    >
                        <v-card-title v-text="`${e.DDATE}, ${e.TTIME}, ${e.STATYPE}`"/>
                        <v-card-subtitle v-text="`${e.MED}`"/>
                        <v-card-text v-text="`${e.INFO}`"/>
                        <v-card-actions>
                            <v-row
                                   justify="end"
                                   class="mb-2 mr-2"
                            >
                                <v-btn small
                                       text
                                       color="error"
                                       @click.prevent="dialogDelSta(e,i)"
                                >
                                    Удалить
                                </v-btn>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-card>

            </v-col>

            <v-col
                    cols="12"
                    sm="6"
            >
                <v-card
                        class="pa-2"
                        elevation="0"
                >
                    <h2>Выбрано</h2>
                    <v-card
                            class="mt-6 pa-2"
                            elevation="0"
                            outlined
                    >


                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title v-html="LPU.name"/>
                                <v-list-item-subtitle v-text="LPU.address"/>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Полис № {{NUM}}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    Дата рождения {{DR}}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>


                    </v-card>
                </v-card>
            </v-col>

        </v-row>
    </v-container>

</template>>

<style>
    .disabled {
        pointer-events: none;
        text-decoration: line-through;
        color: grey;
    }

</style>


<script>
    import post, {postLog} from '../tools/post'
    import {toBack,  toNext,  thisLog, curstaEmpty} from '@/components/app/js/globals'

    export default {
        name: 'Stacancel',
        data: () => ({
            name: 'Stacancel',
            header: 'Список выписанных талонов',
            dialog: false,
            loading: false,
            loadingBtn: false,
            log: true,
            alertError: {
                show: false,
                text: null
            },
            alertWarning: {
                show: false,
                text: null
            },
            delsta: {}, //{}данные по удаленному талону посещения из списка талонов DDATE, TTIME, MED, INFO
            delstai: null, //-1 текущий, иначе индекс в arrsta
            cursta: {}, //{} curstaFillEmpty()данные по заведенному талону посещения из списка талонов DDATE, TTIME, MED, INFO
            arrsta: {} //{}
        }),
        computed: {
            LPU() {
                return this.$store.getters.LPU;
            },
            CCANCEL() {
                return this.$store.getters.CCANCEL;
            },
            NUM() {
                return this.$store.getters.CCANCEL.p_num
            },
            DR() {
                return this.$store.getters.CCANCEL.p_dd+'.'+
                    this.$store.getters.CCANCEL.p_mm+'.'+
                    this.$store.getters.CCANCEL.p_yyyy

            },
            KARD() {
                return this.$store.getters.KARD;
            },
            STA() {
                return this.$store.getters.STA;
            },
            CANCELED() {
                return (this.cursta.NPPSTA?false:true);
            },
            is_actual() {
                return (this.$store.getters.LPU.actual && this.$store.getters.LPU.actual==='1') &&
                    (this.$store.getters.CCANCEL.actual && this.$store.getters.CCANCEL.actual==='1')
            },
            navBackShow() {
                return (this.alertError.show || this.alertWarning.show)
            }
        },
        async beforeMount() {
            this.cursta=curstaEmpty()
            this.$store.dispatch('SET_STA', [])
            //console.log('----',this.is_actual)
            if(this.is_actual) {
                let url, data

                //--------------  список талонов
                url = this.$store.getters.LPU.resturl + '/getstalist'
                thisLog(this, ['url', url])
                data = {
                    nppkard: this.CCANCEL.p_nppkard,
                    opt: '',
                    nppfilial: this.LPU.nppfilial
                }
                thisLog(this, ['nppkard---', this.KARD.p_nppkard])
                thisLog(this, ['opt---', this.KARD.p_opt])
                try {
                    let res = await post(url, data)
                    thisLog(this, [` STALIST >>>>>>>>> `, res]) //NPPSTA, MSG, ERR
                    if (res && res.data) { //ответ получен
                        if(res.data.length>0) {
                            this.arrsta = res.data
                        }else{
                            this.alertWarning.text = "Данных не найдено"
                            this.alertWarning.show = true
                        }
                    }
                } catch (err) {
                    //console.log('sta2',err)
                    this.alertErr.text = "Что-то пошло не так"
                    this.alertEr.show = true
                    //alert(err)
                }

            }
            this.loading=false
            //console.log(this.cursta)

        },
        mounted() {
            if(this.is_actual) {
                //window.scrollTo(0, top)
            }else{
                this.alertWarning.text = "Что-то пошло не так"
                this.alertWarning.show = true
                this.navBack()  //каскадный откат÷
            }
        },
        methods: {
            async navBack(){
                this.drawer=false
                //console.log('navback',this)
                toBack(this,{prev:true, noerr:true, nomsg:true})
            },
            async onOk(){
                await this.$router.push('/')
                //pushBack(this,{prev:true})
            },
            async onDelSta(){

                this.alertWarning.text = `Отмена талона от ${this.STA.ddate} с ${this.STA.timeb} по ${this.STA.timee}, ${this.FIN.name} к ${this.MED.NNAME}...`
                this.alertWarning.show = true
                this.loading=true
                if(this.is_actual && this.STA.nppkard_noreq && this.STA.nppsta) {
                    if(await this.delSta(this.STA.nppsta,this.STA.nppkard_noreq)){
                        alert('Удалено!')
                    }
                }
                this.loading=false
                await this.$router.push('/')

            },
            dialogDelSta(el, i){
                this.delsta=el
                this.delstai=i
                this.dialog=true
            },
            dialogDelStaDo(){
                if(this.delSta(this.delsta.NPPSTA, this.delsta.NPPKARD)){
                    this.delArrSta(this.delstai)
                }
                this.dialog=false
            },
            async delArrSta(i){
                if(i==-1) {
                    this.cursta=curstaEmpty()//                 this.cursta=[]
                    this.alertWarning.text = `Отмена талона от ${this.STA.ddate} с ${this.STA.timeb} по ${this.STA.timee}, ${this.FIN.name} к ${this.MED.NNAME}...`
                    this.alertWarning.show = true
                    this.$store.dispatch('SET_STA', []) //после записи сообщения!!!
                    window.scrollTo(0, top)
                    thisLog(this,['...',this.cursta.NPPSTA,(this.cursta.NPPSTA?'ДА':'НЕТ')])
                }else{
                    if (await this.delSta(this.arrsta[i].NPPSTA, this.arrsta[i].NPPKARD)) {
                        this.arrsta.splice(i, 1)
                    }
                    if(this.arrsta.length==0){
                        this.alertWarning.text = "Данных не найдено"
                        this.alertWarning.show = true
                    }
                }
            },
            async delSta(p_nppsta, p_nppkard){
                let ret=false

                const url = this.$store.getters.LPU.resturl + '/delsta'
                thisLog(this,['url', url])
                let data = {
                    nppsta:                   p_nppsta,
                    nppkard:                  p_nppkard
                }
                thisLog(this,['nppsta---',data.nppsta])
                thisLog(this,['nppkard---',data.nppkard])
                try {
                    let res = await post(url, data)
                    thisLog(this,[` DELSTA >>>>>>>>> `, res]) //NPPSTA, MSG, ERR
                    if (res && res.data && res.data.length == 1) { //ответ получен
                        const el = res.data[0]
                        if(el.ERR===null){
                            ret=true
                        } else {
                            this.alertError.text = el.ERR
                            this.alertError.show = true
                        }
                    } else {
                        this.alertWarning.text = 'Что-то пошло не так'
                        this.alertWarning.show = true
                    }

                } catch (err) {
                    alert(err)
                    postLog(this.$store.getters.LPU.resturl, { err: err, place: 'Stacancel#1' } )
                }
                return ret;
            }
        }
    }
</script>
